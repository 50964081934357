import { initializeApp } from "firebase/app";

import {
    initializeFirestore,
    CACHE_SIZE_UNLIMITED,
    enableIndexedDbPersistence,
    doc,
    setDoc,
    updateDoc,
    onSnapshot,
    arrayUnion,
    arrayRemove
} from "firebase/firestore";

import "swiped-events";

window.loading_start = Date.now();


const rebot = document.querySelector("#rebot");
const today = document.querySelector("#today");
const week = document.querySelector("#week");
const marks = document.querySelector("#marks");
const settings = document.querySelector("#settings");




class App {
    constructor() {
        const docRef = doc(db, "appConfig", "subjects");
        onSnapshot(docRef, (doc) => {
            this.subjManifest = doc.data();
            window.manifest = this.subjManifest;

            globalThis.TimeTable = new TimetableManager();
            globalThis.Marks = new MarksManager();
            globalThis.ReBot = new ReBotManager();



            console.log("App Initialized");
        });

    }

    remake() {
        const data = {
            "1": {
                "1": {
                    "id": "bel",
                    "hw": ""
                },
                "2": {
                    "id": "bel",
                    "hw": ""
                },
                "3": {
                    "id": "bel",
                    "hw": ""
                },
                "4": {
                    "id": "bel",
                    "hw": ""
                },
                "5": {
                    "id": "bel",
                    "hw": ""
                },
                "6": {
                    "id": "bel",
                    "hw": ""
                }
            },
            "2": {
                "1": {
                    "id": "bel",
                    "hw": ""
                },
                "2": {
                    "id": "bel",
                    "hw": ""
                },
                "3": {
                    "id": "bel",
                    "hw": ""
                },
                "4": {
                    "id": "bel",
                    "hw": ""
                },
                "5": {
                    "id": "bel",
                    "hw": ""
                },
                "6": {
                    "id": "bel",
                    "hw": ""
                }
            },
            "3": {
                "1": {
                    "id": "bel",
                    "hw": ""
                },
                "2": {
                    "id": "bel",
                    "hw": ""
                },
                "3": {
                    "id": "bel",
                    "hw": ""
                },
                "4": {
                    "id": "bel",
                    "hw": ""
                },
                "5": {
                    "id": "bel",
                    "hw": ""
                },
                "6": {
                    "id": "bel",
                    "hw": ""
                }
            },
            "4": {
                "1": {
                    "id": "bel",
                    "hw": ""
                },
                "2": {
                    "id": "bel",
                    "hw": ""
                },
                "3": {
                    "id": "bel",
                    "hw": ""
                },
                "4": {
                    "id": "bel",
                    "hw": ""
                },
                "5": {
                    "id": "bel",
                    "hw": ""
                },
                "6": {
                    "id": "bel",
                    "hw": ""
                }
            },
            "5": {
                "1": {
                    "id": "bel",
                    "hw": ""
                },
                "2": {
                    "id": "bel",
                    "hw": ""
                },
                "3": {
                    "id": "bel",
                    "hw": ""
                },
                "4": {
                    "id": "bel",
                    "hw": ""
                },
                "5": {
                    "id": "bel",
                    "hw": ""
                },
                "6": {
                    "id": "bel",
                    "hw": ""
                }
            }

        };

        setDoc(doc(db, "weeks", "1"), data);
    }

}


class log {
    static error(err) {
        console.error(err);
        UI.alert("Произошла ошибка");
    }

    static crashReport(report) {
        const url = "https://wordl1dan.pythonanywhere.com/report";

        report = { a: "Test" };
        const response = fetch(url, {
            method: 'POST',
            body: JSON.stringify(report),
            headers: {
                'Content-Type': 'text/plain'
            },
            credentials: 'include'
        });



    }
}



class ReBotManager {

    constructor() {
        this.box_container = document.querySelector('#boxes');
        this.viewbox_template = document.querySelector('.template #viewbox');
        this.status_title = document.querySelector('#current-subj');
        this.search = document.querySelector('.search input');

        onSnapshot(user.workspace, (doc) => {
            if (!doc.metadata.hasPendingWrites) {
                const data = doc.data();

                if (data && data.nums) {
                    this.open(data.subj, data.nums);
                }
            }
        });


        document.querySelectorAll("#rebot header button").forEach(function(el) {
            el.onclick = function() { UI.getInput(function(id, nums) { ReBot.open(id, nums); }, el.id); };
        });


        this.search.addEventListener("keydown", event => {
            if (event.key == "Enter") {
                window.open("https://www.google.com/search?q=" + this.search.value, '_blank');
                this.search.value = "";
            }
        });
    }


    open(id, num) {
        let nums;

        if (num == undefined) {
            log.crashReport("ddd");
            nums = prompt('Введи номер или номера через запятую:');
            if (nums) {
                nums = nums.split(',');
            } else return;

            for (num of nums) {
                setDoc(user.workspace, {
                    "subj": id,
                    "nums": arrayUnion(parseInt(num))
                }, { merge: true });
            }

        } else {
            nums = num;
        }



        const subj = window.manifest[id];

        this.status_title.textContent = subj.title;

        for (let num of nums) {
            if (!num) {
                continue;
            }
            if (this.box_container.querySelector(`.viewbox[num='${num}']`)) return;

            let box = this.viewbox_template.cloneNode(true);

            if (subj.section == false) {
                box.querySelector("#view").src = subj.url.replace('?', num);

                if (subj.full_img == false) {
                    box.querySelector("#view2").src = subj.url.replace('?', num + "_2");
                    box.querySelector("#view3").src = subj.url.replace('?', num + "_3");
                }

            } else {
                box.querySelector("#prew").remove();
                box.querySelector("#next").remove();
                box.querySelector("#alt-re").remove();

                box.querySelector("#view").src = subj.url.replace('*', num).replace('?', 1);

                let i = 2;
                let img;

                while (i < 12) {
                    img = box.querySelector("#view").cloneNode(true);
                    img.onerror = function a() { this.remove(); };
                    img.src = subj.url.replace('*', num).replace('?', i);
                    box.querySelector(".viewbox-content").append(img);
                    i++;
                }
            }

            this.box_container.prepend(box);
            box.querySelector(".num").textContent = num;
            box.setAttribute("num", num);
            box.setAttribute("subj_id", id);
        }


    }

    change_num(btn, delta) {
        const box = btn.parentNode.parentNode;
        let subj = window.manifest[box.getAttribute("subj_id")];
        let num = parseInt(box.getAttribute('num')) + delta;

        box.querySelector("#view").src = subj.url.replace('?', num);

        if (subj.full_img == false) {
            box.querySelector("#view2").src = subj.url.replace('?', num + "_2");
            box.querySelector("#view3").src = subj.url.replace('?', num + "_3");
        }

        box.setAttribute('num', num);
        box.querySelector(".num").textContent = num;
    }

    remove(box) {
        const num = parseInt(box.getAttribute('num'));

        box.remove();

        if (!(this.box_container.hasChildNodes())) {
            this.status_title.textContent = "ReBot";
            setDoc(user.workspace, {
                "nums": null,
                "subj": null
            }, { merge: true });
        } else {
            setDoc(user.workspace, {
                "nums": arrayRemove(num)
            }, { merge: true });
        }
    }

    open_alt(box) {
        let num = box.getAttribute('num');
        let url = manifest[box.getAttribute('subj_id')].alt_url.replace("?", num);

        if (url == '') {
            alert('Для этого предмета нет другого решебника');
            return;
        }

        window.open(url, '_self');
    }

}


class TimetableManager {

    constructor() {
        this.now = document.querySelector('.now');
        this.today = document.querySelector('.table_today');
        this.week = document.querySelector('.table_full');

        this.day_template = document.querySelector('#day-template .table');
        this.row_template = document.querySelector('#day-template .row');

        this.day_titles = {
            "1": "Понедельник",
            "2": "Вторник",
            "3": "Среда",
            "4": "Четверг",
            "5": "Пятница",
            "6": "Суббота",
            "7": "Воскресенье"
        };


        onSnapshot(doc(db, "weeks", "1"), (docs) => {
            this.tt_data = docs.data();
            this.load();


            /*for (let i in this.tt_data) {
                const docum = doc(db, "timeTable", "9");
                setDoc(docum, {
                    [i]: this.tt_data[i]

                }, { merge: true });
            }
            */
        });

    }

    composite_day(day_num) {
        let block, lesson, subj_title, homework_title, day;

        day = this.day_template.cloneNode(true);
        day.querySelector("h1").textContent = this.day_titles[day_num];
        day.id = day_num;
        let day_content = day.querySelector(".content");

        for (lesson in this.tt_data[day_num]) {
            block = this.row_template.cloneNode(true);
            block.id = this.tt_data[day_num][lesson].id;
            block.setAttribute("lesson_num", lesson);

            if (this.tt_data[day_num][lesson].hw) {
                block.setAttribute("data-hw", this.tt_data[day_num][lesson].hw);
            }

            subj_title = block.querySelector(".subj");
            homework_title = block.querySelector(".homework");
            subj_title.textContent = window.manifest[this.tt_data[day_num][lesson].id].title;
            subj_title.style.backgroundColor = window.manifest[this.tt_data[day_num][lesson].id].color;
            homework_title.textContent = this.tt_data[day_num][lesson].hw;

            day_content.append(block);
        }

        return day;
    }

    load_week() {
        let day_num, day;

        for (day_num in this.tt_data) {
            day = this.composite_day(day_num);
            day.id = day_num;
            this.week.append(day);
        }

    }

    load_today() {
        let day_num = new Date().getDay();

        if (day_num == 0 || day_num == 6) { day_num = 1; }
        let day = this.composite_day(day_num);
        this.today.append(day);
    }



    load() {
        this.today.innerHTML = "";
        this.week.innerHTML = "";

        this.load_today();

        //let now = new Date();
        //const dayNum = now.getUTCDay() || 7;
        // now.setUTCDate(now.getUTCDate() + 4 - dayNum);
        //const yearStart = new Date(Date.UTC(now.getUTCFullYear(),0,1));

        // const weekNum = Math.ceil((((now - yearStart) / 86400000) + 1)/7);

        this.load_week();
    }

    /*
    edit_homework(label) {
        let newTxt = prompt("Изменить домашнее", label.textContent);
        if (newTxt == null) { return; }


        let day = label.parentNode.parentNode.parentNode.id;
        let lesson = label.parentNode.getAttribute("lesson_num");

        updateDoc(doc(db, "weeks", "1"), {
            [day + "." + lesson + ".hw"]: newTxt
        });
    */

    edit_homework(label) {
        if (label.querySelector("input")) return;

        const input = document.createElement("input");
        input.type = "number";
        label.append(input);
        input.focus();

        const day = input.parentNode.parentNode.parentNode.parentNode.id;
        const lesson = input.parentNode.parentNode.getAttribute("lesson_num");

        input.addEventListener("blur", () => {
            input.remove();
        });

        input.addEventListener("keydown", function checkKey(event) {
            if (event.key == "Enter") {
                updateDoc(doc(db, "weeks", "1"), {
                    [day + "." + lesson + ".hw"]: input.value
                });
                input.blur();
            }
        });
    }

    share_day(day) {
        const rows = Array.from(day.querySelectorAll(".row[data-hw]"));
        const title = day.querySelector("h1").textContent;

        let toShare = "";

        for (let row of rows) {
            const title = manifest[row.id].title;
            const hw = row.getAttribute("data-hw");

            if (hw) {
                toShare += `${title}: ${hw}\n`;
            }
        }

        if (toShare != "") {
            navigator.share({ title: title, text: toShare });
        } else {
            UI.alert("Нечем делиться(");
        }




    }

    openDayInstant(day) {
        const rows = Array.from(day.querySelectorAll(".row[data-hw]"));

        //let i, max, row;

        // if (length == 0) {
        //    UI.alert("Нет дз на этот день");
        //   return;
        //} 

        //if (length <= 2) {
        // for(i=0, length; i < length; i+=1) {
        //  row = rows[i];
        //  InstantView.open(row.id, row.getAttribute("data-hw"));
        //  }
        // } 

        //const left = rows.slice(0, length / 2);
        //const right = rows.slice(length / 2);

        //console.log(left, right)

        InstantView.openMany(rows);
    }

}

class SettingsManager {
    constructor() {

        onSnapshot(user.settings, (doc) => {
            if (doc.data() == undefined) {
                setDoc(user.settings, {
                    "inversion_set": true,
                    "stealth_set": false,
                    "theme": "dark"
                });
            }

            if (!doc.metadata.hasPendingWrites) {
                let config = doc.data();
                for (let i in doc.data()) {
                    localStorage[i] = config[i];
                }
                this.load();
            }
        });
    }

    load() {
        if (localStorage.stealth_set == "true") {
            document.querySelector('#stealth_set').checked = true;

            document.querySelectorAll(".stealth").forEach(el => {
                el.style.display = "none";
            });

            document.querySelectorAll(".subject-block").forEach(el => {
                el.style.gridTemplateColumns = "88px 1fr";
            });
        }
        if (localStorage.inversion_set == "true") {
            document.querySelector('#inversion_set').checked = true;
            document.documentElement.style.setProperty('--inv', 0.87);
        } else {
            document.documentElement.style.setProperty('--inv', 0);
            document.querySelector('#inversion_set').checked = false;
        }

        if (localStorage.theme) {
            document.documentElement.setAttribute("theme", localStorage.theme);
        }

    }


    set(checkbox) {
        localStorage.setItem(checkbox.id, checkbox.checked);
        setDoc(user.settings, {
            [checkbox.id]: checkbox.checked
        }, { merge: true });

        if (checkbox.id == "stealth_set") {
            if (checkbox.checked) {
                UI.alert("Стелс мод включен)");
            } else {
                location.reload();
            }
        }

        this.load();
    }

    set_theme(theme) {
        document.documentElement.setAttribute("theme", theme);
        UI.alert("Тема изменена");

        setDoc(user.settings, {
            "theme": theme
        }, { merge: true });

        localStorage.setItem("theme", theme);
    }

}










class InstantView {

    static init() {
        let window1 = document.querySelector("#inst1");
        let window2 = document.querySelector("#inst2");

        this.window1 = window1;
        this.window2 = window2;

        window1.addEventListener('swiped-left', function(e) {
            InstantView.close(e.target.closest(".instant-view"));
        });

        window2.addEventListener('swiped-right', function(e) {
            InstantView.close(e.target.closest(".instant-view"));
        });

        window1.addEventListener('swiped-right', function(e) {
            InstantView.switch(e.target.closest(".instant-view"));
        });

        window2.addEventListener('swiped-left', function(e) {
            InstantView.switch(e.target.closest(".instant-view"));
        });



    }


    static open(subj_id, nums, content) {
        let win;
        let subj = manifest[subj_id];

        let window1 = document.querySelector("#inst1");
        let window2 = document.querySelector("#inst2");

        nums = nums.split(',');

        if (nums == false && content == undefined) {
            UI.alert('Для этого предмета не записано дз');
            return;
        }

        if (window1.classList.contains("active")) {
            win = window2;
            document.querySelectorAll(".app").forEach((el) => {
                el.classList.add("noscroll");
            });
            window.instRight = true;
            window.instLeft = true;
        } else {
            if (window.orientation == 90 || window.screen.width >= 800) {
                document.querySelector("body").classList.add("min-right");
            } else {
                document.querySelectorAll(".app").forEach((el) => {
                    el.classList.add("noscroll");
                });
            }
            win = window1;
            window.instLeft = true;
            if (window2.classList.contains("active")) {
                window.instRight = true;
            }
        }


        if (content) {
            win.querySelector(".content").innerHTML = content;
            win.classList.add("active");
            return;
        } else {
            win.querySelector(".content").innerHTML = "";
        }

        let viewbox = win.querySelector(".content");

        for (let num of nums) {
            num = num.replace(/\D/g, "");

            if (subj.section == false) {
                let img1 = document.createElement("img");
                img1.src = subj.url.replace('?', num);
                img1.onerror = (ev) => { ev.target.remove(); };
                img1.style.width = "100%";

                viewbox.append(img1);

                if (subj.full_img == false) {
                    let img2, img3 = viewbox.querySelector("img").cloneNode(true);

                    img2.src = subj.url.replace('?', num + "_2");
                    img3.src = subj.url.replace('?', num + "_3");

                    viewbox.append(img2, img3);
                }
            } else {
                let i = 1;
                let img;

                while (i < 11) {
                    img = document.createElement("img");
                    img.src = subj.url.replace('*', num).replace('?', i);
                    img.style.width = "100%";
                    img.onerror = (ev) => { ev.target.remove(); };

                    viewbox.append(img);
                    i++;
                }
            }
        }

        win.classList.add("active");
    }

    static openMany(rows, content) {
        let win;


        if (this.window1.classList.contains("active")) {
            win = this.window2;
            document.querySelectorAll(".app").forEach((el) => {
                el.classList.add("noscroll");
            });
            window.instRight = true;
            window.instLeft = true;
        } else {
            if (window.orientation == 90 || window.screen.width >= 800) {
                document.querySelector("body").classList.add("min-right");
            } else {
                document.querySelectorAll(".app").forEach((el) => {
                    el.classList.add("noscroll");
                });
            }
            win = this.window1;
            window.instLeft = true;
            if (this.window2.classList.contains("active")) {
                window.instRight = true;
            }
        }

        if (content) {
            win.querySelector(".content").innerHTML = content;
            win.classList.add("active");
            return;
        } else {
            win.querySelector(".content").innerHTML = "";
        }

        const viewbox = win.querySelector(".content");

        for (let row of rows) {
            const subj = manifest[row.id];
            const nums = row.getAttribute("data-hw").split(",");


            for (let num of nums) {
                num = num.replace(/\D/g, "");

                if (subj.section == false) {
                    let img1 = document.createElement("img");
                    img1.src = subj.url.replace('?', num);
                    img1.onerror = (ev) => { ev.target.remove(); };
                    img1.style.width = "100%";

                    viewbox.append(img1);

                    if (subj.full_img == false) {
                        let img2, img3 = viewbox.querySelector("img").cloneNode(true);

                        img2.src = subj.url.replace('?', num + "_2");
                        img3.src = subj.url.replace('?', num + "_3");

                        viewbox.append(img2, img3);
                    }
                } else {
                    let i = 1;
                    let img;

                    while (i < 11) {
                        img = document.createElement("img");
                        img.src = subj.url.replace('*', num).replace('?', i);
                        img.style.width = "100%";
                        img.onerror = (ev) => { ev.target.remove(); };

                        viewbox.append(img);
                        i++;
                    }
                }
            }

        }

        win.classList.add("active");



        /*

        let viewbox = win.querySelector(".content");

        for (let num of nums) {
            num = num.replace(/\D/g, "");

            if (subj.section == false) {
                let img1 = document.createElement("img");
                img1.src = subj.url.replace('?', num);
                img1.onerror = (ev) => { ev.target.remove(); };
                img1.style.width = "100%";

                viewbox.append(img1);

                if (subj.full_img == false) {
                    let img2, img3 = viewbox.querySelector("img").cloneNode(true);

                    img2.src = subj.url.replace('?', num + "_2");
                    img3.src = subj.url.replace('?', num + "_3");

                    viewbox.append(img2, img3);
                }
            } else {
                let i = 1;
                let img;

                while (i < 11) {
                    img = document.createElement("img");
                    img.src = subj.url.replace('*', num).replace('?', i);
                    img.style.width = "100%";
                    img.onerror = (ev) => { ev.target.remove(); };

                    viewbox.append(img);
                    i++;
                }
            }
        }

        win.classList.add("active"); */
    }

    static
    switch (win) {
        if (window.instLeft && window.instRight || (window.orientation == 0 && window.screen.width <= 800)) { return; }

        let content = win.querySelector(".content").innerHTML;

        this.open("", "", content);
        this.close(win);
    }




    static close(win) {
        win.classList.remove("active");
        win.addEventListener("transitionend", () => {
            if (!(win.classList.contains("active"))) {
                let content = win.querySelector(".content");
                content.innerHTML = "";
                content.scrollTop = 0;
            }
        }, { once: true });

        if (win.getAttribute("side") == "left") {
            window.instLeft = false;
        } else {
            window.instRight = false;
        }

        let ActiveLeft = window.instLeft;
        let ActiveRight = window.instRight;
        let body = document.body.classList;

        if (!(ActiveLeft || ActiveRight)) {
            body.remove("min-left", "min-right");
        } else if (ActiveRight && !(ActiveLeft)) {
            body.remove("min-right");
            body.add("min-left");
        } else {
            body.remove("min-left");
            body.add("min-right");
        }

        document.querySelectorAll(".app").forEach((el) => {
            el.classList.remove("noscroll");
        });
    }
}

InstantView.init();

class MarksManager {
    constructor() {

        onSnapshot(user.marks, (doc) => {
            this.marks = doc.data();
            if (!this.marks) {
                this.marks = {};
            }
            this.load();
        });
    }

    load() {
        let manifest = window.manifest;
        let table = document.querySelector("#marks .table .content");
        table.innerHTML = "";
        let subj_template = document.querySelector("#marks .template .row");
        let subj, row, title, marks_label, average_label, key, marks_list, sum, average;

        for (key in manifest) {
            subj = manifest[key];

            if (subj.marks == true) {
                row = subj_template.cloneNode(true);
                row.id = key;

                title = row.querySelector(".subj");
                marks_label = row.querySelector(".marks-label");
                average_label = row.querySelector(".average");

                title.textContent = subj.title;
                title.style.backgroundColor = subj.color;


                if (this.marks && this.marks[key]) {
                    marks_label.textContent = this.marks[key];

                    marks_list = this.marks[key].split(",");

                    sum = 0;

                    for (let mark of marks_list) {
                        mark = parseInt(mark.replace(/\D/g, ""));

                        sum = sum + mark;

                    }

                    average = sum / marks_list.length;
                    if (average) {
                        average_label.textContent = +average.toFixed(1);
                    }
                }

                table.append(row);
            }

        }
    }

    add(subj_id) {
        let subj = window.manifest[subj_id];

        let new_mark = prompt("Новая оценка -- " + subj.title + ":");

        if (new_mark == null) {
            return;
        }

        new_mark = new_mark.replace(" ", "");

        if (new_mark > 10 || new_mark < 1) {
            UI.alert("Так нельзя..");
            return;
        }

        let old_marks = this.marks[subj_id];

        if (old_marks == null || old_marks == "") {
            setDoc(user.marks, {
                [subj_id]: new_mark
            }, { merge: true });
        } else {
            setDoc(user.marks, {
                [subj_id]: old_marks + "," + new_mark
            }, { merge: true });
        }
    }

    change(subj_id) {
        let subj = window.manifest[subj_id];
        let old_marks = this.marks[subj_id];

        let new_marks = prompt("Изменить оценки -- " + subj.title + ":", old_marks).replace(" ", "");

        if (new_marks == null) {
            return;
        }

        setDoc(user.marks, {
            [subj_id]: new_marks
        }, { merge: true });

    }
}


window.addEventListener("offline", function(ev) {
    document.querySelectorAll(".offline-alert").forEach((el) => {
        el.classList.add("active");
    });
});

window.addEventListener("online", function(ev) {
    document.querySelectorAll(".offline-alert").forEach((el) => {
        el.classList.remove("active");
    });
});



var firebaseConfig = {
    apiKey: "AIzaSyAkNpqlq9hU_cDu1_4wQIBNNc9OJd4LT1g",
    authDomain: "rebot-f643e.firebaseapp.com",
    projectId: "rebot-f643e",
    storageBucket: "rebot-f643e.appspot.com",
    messagingSenderId: "329205426356",
    appId: "1:329205426356:web:d8c730df77b0d7b9890fe1"
};


globalThis.app = initializeApp(firebaseConfig);

globalThis.db = initializeFirestore(app, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
});


enableIndexedDbPersistence(db, { forceOwnership: true })
    .catch((err) => {
        console.log(err.code);
    });

/*
let openRequest = indexedDB.open("rebotData", 2);

openRequest.onupgradeneeded = function() {
    //window.location = "/auth.html";
};

openRequest.onsuccess = function() {
    const Idb = openRequest.result;
    const transaction = Idb.transaction(["userStorage"], "readonly");
    
    const userStorage = transaction.objectStore("userStorage");
    
    userStorage.get("user").onsuccess = function(event) {

        const userData = event.target.result;
        console.log(event.target.result);
        globalThis.user = {
            "id": userData.id,
            "settings": doc(db, userData.id, "config"),
            "workspace": doc(db, userData.id, "workspace"),
            "marks": doc(db, userData.id, "marks")
        };
    
        
        globalThis.app = new App();
        globalThis.Settings = new SettingsManager();
        Settings.load();
    };


};
*/


const userData = {
    "id": 1046049249,
    "first_name": "Даник",
    "username": "world1dan",
    "photo_url": "https://t.me/i/userpic/320/-wjS6LMe-1tZv-m0sojDlCBc1O5kGM5yQZdJPDAaTCY.jpg",
    "auth_date": 1626863014,
    "hash": "00bbd3863ce078e160bd57b31287c7959dfff66af0a00d6357b2446fbbcaa052"
};

const id = userData.id.toString();
console.log(id);

globalThis.user = {
    "id": userData.id,
    "settings": doc(db, id, "config"),
    "workspace": doc(db, id, "workspace"),
    "marks": doc(db, id, "marks")
};


globalThis.app = new App();
globalThis.Settings = new SettingsManager();
Settings.load();







class UI {

    static alert(text) {
        let alert = document.querySelector(".alert");
        alert.textContent = text;

        alert.style = "display: block; animation: alert 0.45s cubic-bezier(.5,.1,.3,1); animation-fill-mode: forwards;";

        if (window.active_timeout) {
            clearTimeout(window.active_timeout);
        }


        window.active_timeout = setTimeout(() => {

            alert.style = "display: block; animation: alert-down 0.4s cubic-bezier(.5,.1,.3,1); animation-fill-mode: forwards;";

            alert.addEventListener("animationend", () => {
                alert.style = "";
            }, { once: true });

            window.active_timeout = undefined;
        }, 1400);
    }

    static open(target) {
        target.style = "display: block; animation: slide-left 0.6s cubic-bezier(.5,.1,.3,1); animation-fill-mode: forwards;";
    }

    static slide(target) {
        target.classList.toggle("active");
    }

    static change_tab(target, btn) {
        const toClose = document.querySelector(".app.active");

        if (toClose == target) return;

        toClose.classList.remove("active");
        target.classList.add("active");

        toClose.scrollTo(0, 0);
        target.scrollTo(0, 0);

        window.scrollTo(0, 0);
        document.body.scrollTo(0, 0);

        if (btn) {
            document.querySelector(".bottom-nav span[active='true']").setAttribute("active", "false");
            btn.setAttribute("active", "true");
        }
    }

    static getInput(callback, param) {
        const searchWraper = document.querySelector("#rebot .search");

        const inputRebot = searchWraper.querySelector("input.rebot");
        const inputGoogle = searchWraper.querySelector("input.google");

        const iconGoogle = searchWraper.querySelector("img.google");
        const iconRebot = searchWraper.querySelector("img.rebot");

        inputRebot.classList.add("active");
        inputGoogle.classList.remove("active");

        inputRebot.focus();

        iconGoogle.classList.remove("active");
        iconRebot.classList.add("active");

        inputRebot.addEventListener("keydown", function handle(event) {
            if (event.key == "Enter") {
                const nums = inputRebot.value.replace(" ", "").split(',');

                callback(param, nums);

                inputRebot.blur();

                inputRebot.removeEventListener("keydown", handle);
            }
        });

        inputRebot.addEventListener("blur", function onBlur() {
            inputRebot.value = "";

            inputRebot.classList.remove("active");
            inputGoogle.classList.add("active");

            iconRebot.classList.remove("active");
            iconGoogle.classList.add("active");

            inputRebot.removeEventListener("blur", onBlur);
        }, { once: true });


    }
}



class SecurityManager {
    constructor() {
        this.lockscreen = document.querySelector("#lockscreen");
        this.indicator = lockscreen.querySelector("#indicator");

        this.lockscreen.querySelectorAll("#numpad button").forEach((btn) => {
            btn.addEventListener("click", this.addCodeNum);
        });
    }
    addCodeNum(event) {
        const lockscreen = document.querySelector("#lockscreen");
        const indicator = lockscreen.querySelector("#indicator");
        const invalidAlert = lockscreen.querySelector("#invalid-alert");
        const unactiveDots = indicator.querySelectorAll(".char:not(.active)");

        if (unactiveDots) {
            let password = lockscreen.getAttribute("data-password");

            password += event.target.textContent;

            lockscreen.setAttribute("data-password", password);

            unactiveDots[0].classList.add("active");
        }

        if (unactiveDots.length == 1) {
            const password = lockscreen.getAttribute("data-password");

            if (localStorage.lockCode == password) {
                lockscreen.classList.add("unactive");
            } else {
                lockscreen.setAttribute("data-password", "");
                const dots = indicator.querySelectorAll(".char").forEach((dot) => {
                    dot.classList.remove("active");
                    invalidAlert.classList.add("active");
                });
            }
        }
    }


    changeLockCode(form) {
        const oldCodeInput = form.querySelector("input#oldCode");
        const newCodeInput = form.querySelector("input#newCode");

        const oldCode = localStorage.lockCode;

        if (oldCode) {
            if (oldCodeInput.value != oldCode) {
                UI.alert("Неверный код");
                oldCodeInput.value = "";
                return;
            }
        }

        const newCode = newCodeInput.value.replace(/\D/g, '');

        if (newCode.length == 4) {
            localStorage.lockCode = newCode;
            UI.alert("Код установлен");
            UI.slide(securitySettings);
            oldCodeInput.value = "";
            newCodeInput.value = "";
        } else if (newCode.length == 0) {
            localStorage.lockCode = "";
            UI.alert("Код отключен");
            UI.slide(securitySettings);
            oldCodeInput.value = "";
            newCodeInput.value = "";
        } else {
            UI.alert("Код должен состоять из 4 цифр");
        }



    }

}

Security = new SecurityManager();